import React from 'react';
import Billboard, { BillboardProps } from 'components/ribbons/billboard';
import errorBoundary from 'presentation/hocs/errorBoundary';
// eslint-disable-next-line max-len
import DomainSearchForm from 'components/ribbons/domain-search-start/DomainSearchForm';
import { DomainSearchFormProps } from '../domain-search-start';
import pureEnhance from '@ux/pure-enhance';

export interface OuterProps extends DomainSearchFormProps, BillboardProps {}
interface InnerProps extends OuterProps {}

const PureBillboardDomain = ({
  theme,
  colorway,
  search,
  form,
  ...billboardProps
}: InnerProps) => (
  <Billboard
    theme={theme}
    colorway={colorway}
    {...billboardProps}
    additionalText={(
      <DomainSearchForm
        search={search}
        form={form}
        theme={theme}
      />
    )}
  />
);

const enhance = pureEnhance<InnerProps, OuterProps>(
  'BillboardDomain',
  {
    hocs: [
      errorBoundary,
    ],
  }
);

export default enhance(PureBillboardDomain);
