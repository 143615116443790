import React, { CSSProperties } from 'react';
import { Theme, gu2px } from '@ux/theme-utils';
import { ReduxFormSearchInput } from '@ux/search-input';
import DebouncedField from 'redux-form-debounce-field';
import { InjectedFormProps } from 'redux-form';
import pureEnhance from '@ux/pure-enhance';
import { useStyles } from '@team-griffin/stylr';
import { withProps } from '@team-griffin/rehook';

export interface Styles {
  search: CSSProperties,
}

export interface OuterProps {
  search: {
    tld: string,
    glyph: string,
    label: string,
    placeholder?: string,
    destination?: string,
  },
  form: InjectedFormProps,
  theme: Theme,
}
export interface InnerProps extends OuterProps {
  styles: Styles,
}

const stylesheet = (props: InnerProps) => {
  const {
    theme,
  } = props;

  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      search: {
        marginTop: gu2px(3, spacing),
      },
    },
  }, []);
};

const DomainSearchForm = ({
  search,
  form,
  theme,
  styles,
}: InnerProps) => {
  const {
    tld,
    glyph,
    label,
    placeholder,
  } = search;
  return (
    <div style={styles.search}>
      <form
        method="POST"
        onSubmit={form.handleSubmit}
        data-selector="domain-search-form"
      >
        <DebouncedField
          name="domain"
          suffix={tld}
          inputProps={{
            placeholder,
          }}
          button={{
            text: label,
            glyph: glyph,
          }}
          theme={theme}
          //@ts-ignore
          component={ReduxFormSearchInput}
        />
      </form>
    </div>
  );
};

const enhance = pureEnhance('SearchForm', {
  hooks: [
    withProps(stylesheet),
  ],
});

export default enhance(DomainSearchForm);
